import {Props} from '.';

const Icon = (props: Props): JSX.Element => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.0625 12.5938V10.0625C12.0625 9.75781 11.8047 9.5 11.5 9.5C11.1719 9.5 10.9375 9.75781 10.9375 10.0625V12.875C10.9375 13.0625 11.0078 13.2266 11.1484 13.3438L12.6484 14.4688C12.7422 14.5391 12.8594 14.5625 12.9766 14.5625C13.1406 14.5625 13.3047 14.4922 13.4219 14.3516C13.6094 14.0938 13.5625 13.7422 13.3047 13.5547L12.0625 12.5938ZM11.4766 6.5C8.14844 6.5 5.47656 9.19531 5.47656 12.5C5.47656 15.8281 8.14844 18.5 11.4766 18.5C14.7812 18.5 17.4531 15.8281 17.4531 12.5C17.4531 9.19531 14.8047 6.5 11.4766 6.5ZM11.5 17.375C8.80469 17.375 6.625 15.1953 6.625 12.5C6.625 9.82812 8.80469 7.625 11.5 7.625C14.1719 7.625 16.375 9.82812 16.375 12.5C16.375 15.1953 14.1719 17.375 11.5 17.375Z'
      fill='#171717'
    />
  </svg>
);

export default Icon;
