import {Props} from '.';

const Icon = (props: Props): JSX.Element => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M18.6562 12.8516L16.4062 17.3516C16.2891 17.6094 16.0312 17.75 15.7266 17.75H6.75C5.90625 17.75 5.25 17.0938 5.25 16.25V8.75C5.25 7.92969 5.90625 7.25 6.75 7.25H9.49219C9.89062 7.25 10.2656 7.41406 10.5469 7.69531L11.6953 8.75H15C15.8203 8.75 16.5 9.42969 16.5 10.25V11H15.375V10.25C15.375 10.0625 15.1875 9.875 15 9.875H11.25L9.75 8.49219C9.67969 8.42188 9.58594 8.375 9.49219 8.375H6.75C6.53906 8.375 6.375 8.5625 6.375 8.75V15.5L8.03906 12.1719C8.15625 11.9141 8.41406 11.75 8.69531 11.75H18C18.5391 11.75 18.9141 12.3359 18.6562 12.8516Z' />
  </svg>
);

export default Icon;
