import {Props} from '.';

const Icon = ({className, style, title}: Props): JSX.Element => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    style={style}
  >
    {title && <title>{title}</title>}
    <path d='M12 9.875C11.7891 9.89844 11.4609 9.94531 11.25 9.99219C11.3438 10.1562 11.4141 10.4609 11.4375 10.625C11.4375 11.3516 10.8281 11.9375 10.125 11.9375C9.9375 11.9375 9.63281 11.8672 9.49219 11.7734C9.42188 11.9844 9.375 12.2891 9.375 12.5C9.375 13.9531 10.5469 15.125 12 15.125C13.4531 15.125 14.625 13.9531 14.625 12.5C14.625 11.0703 13.4531 9.875 12 9.875ZM18.6562 12.1719C17.3906 9.6875 14.8594 8 12 8C9.11719 8 6.58594 9.6875 5.32031 12.1719C5.27344 12.2656 5.25 12.4062 5.25 12.5234C5.25 12.6172 5.27344 12.7578 5.32031 12.8516C6.58594 15.3359 9.11719 17 12 17C14.8594 17 17.3906 15.3359 18.6562 12.8516C18.7031 12.7578 18.7266 12.6172 18.7266 12.5C18.7266 12.4062 18.7031 12.2656 18.6562 12.1719ZM12 15.875C9.67969 15.875 7.54688 14.5859 6.42188 12.5C7.54688 10.4141 9.67969 9.125 12 9.125C14.2969 9.125 16.4297 10.4141 17.5547 12.5C16.4297 14.5859 14.2969 15.875 12 15.875Z' />
  </svg>
);

export default Icon;
