import {Props} from '.';

const Icon = (props: Props): JSX.Element => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fill='currentColor'
      d='M10.6875 15.6875H7.28906C6.98438 15.6875 6.75 15.9453 6.75 16.25C6.75 16.5781 6.98438 16.8125 7.28906 16.8125H10.6875C10.9922 16.8125 11.25 16.5781 11.25 16.25C11.25 15.9453 10.9922 15.6875 10.6875 15.6875ZM16.6875 8.1875H7.28906C6.98438 8.1875 6.75 8.44531 6.75 8.75C6.75 9.03125 6.98438 9.3125 7.28906 9.3125H16.6875C16.9922 9.3125 17.25 9.07812 17.25 8.77344C17.25 8.49219 16.9922 8.1875 16.6875 8.1875ZM13.6875 11.9375H7.28906C6.98438 11.9375 6.75 12.1953 6.75 12.5C6.75 12.8281 6.98438 13.0625 7.28906 13.0625H13.6641C13.9922 13.0625 14.25 12.8281 14.25 12.5C14.25 12.1953 13.9922 11.9375 13.6875 11.9375Z'
    />
  </svg>
);

export default Icon;
